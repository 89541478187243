import burgerToggle from './burger';
import popupOpen from './popup-open';
import headerScroll from './header-scroll';
import formSubmitHandler from './form-submit-handler';
import popupClose from './popup-close';
import makeImagesParallax from './make-images-parallax';

document.addEventListener("DOMContentLoaded", () => {
  const burgerItem = document.querySelector('.j-burger');
  if (burgerItem) {
    burgerToggle(burgerItem);
  }

  const linkOpenPopup = Array.from(document.querySelectorAll('.j-popup-open'));
  if (linkOpenPopup) {
    linkOpenPopup.forEach((item) => popupOpen(item));
  }

  const closePopupButtons = Array.from(document.querySelectorAll('.j-popup__close'));
  const popupOverlays = Array.from(document.querySelectorAll('.j-overlay'));
  if (closePopupButtons.length > 0 && popupOverlays.length > 0) {
    closePopupButtons.forEach((item) => popupClose(item));
    popupOverlays.forEach((item) => popupClose(item));
  }

  const header = document.querySelector('.j-header');
  if (header) {
    headerScroll(header);
  }

  setTimeout(() => {
    const forms = Array.from(document.querySelectorAll('.j-form'));
    if (forms.length > 0) {
      forms.forEach((form) => formSubmitHandler(form));
    }
  }, 0);

  setTimeout(() => {
    const imagesForParallax = document.querySelectorAll('.j-img-parallax');
    if (imagesForParallax.length > 0) {
      makeImagesParallax(imagesForParallax);
    }
  }, 0);
});
